import React from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import Layout from '~/components/layout'
import SEO from '~/components/seo'

const MentionsPage = ({ data: { allStrapiMentions } }) => {
  const mentionsData = allStrapiMentions.nodes[0]
  const seo = {
    title: 'Mentions légales',
    metaDescription: 'Découvez les informations relatives au site.',
  }
  return (
    <Layout>
      <SEO seo={seo} />
      <div
        className="bg-no-repeat bg-cover h-full"
        style={{ backgroundImage: `url(${mentionsData.body.background.url})` }}
      >
        <div className={`grid grid-cols-12 container laptop:m-auto`}>
          <div className="col-start-1 col-end-13 min-h-screen">
            <ReactMarkdown className="bg-white p-3 mb-10 push-content tablet:mx-5">
              {mentionsData.body.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query mentionsQuery {
    allStrapiMentions {
      nodes {
        body {
          content
          background {
            url
          }
        }
      }
    }
  }
`

export default MentionsPage
